/* nav */
nav{
    text-align: center;
}

@media(max-width: @screen-xs-max) {
    .pagination{
        margin: 10px 0px 20px 0px;
    }
    .pagination>li>a, .pagination>li>span{
        padding: 3px 7px;
    }
    /*Модули в виде pills в теле*/
    .vmsd fieldset{
        padding: 3px;
    }
}

ol.breadcrumb {
    background-color: transparent;
    padding:0;
    margin: 0px;
    li{
		position:relative;
		padding: 8px 0px;
		span.hidden-xs{
			padding: 0px 4px;
		}
    }
	@media(max-width: @screen-xs-min){
		li{
			max-width: 100%;
			overflow: hidden;
		}
		div.btn-group{
			max-width: 100%;
			overflow: hidden;
			button {
	    		position: absolute;
	    		right: 0px;
			}
		}
	}
}

.nav.pills-xs > li > a { &:extend(.btn-xs); }
.nav.pills-sm > li > a { &:extend(.btn-sm); }
.nav.pills-lg > li > a { &:extend(.btn-lg); }