/* ----carousel------*/
.owl-carousel {
  margin-bottom: @grid-gutter-width/2;
  
  .owl-item {
    img {
      display: inline-block !important;
      width: auto !important;
    }
  }
}

.owl-light .owl-nav { 
  color: @gray; 
  &:hover {
    color: @gray-light;
  }
}

/*owl carousel */
.owl-nav {
  top: 50%;
  margin-top: -16px;
  position:absolute;
  color: rgba(2,63,117,0.6);
  font-size: 32px;
  
  @media (max-width: @screen-xs-max) {
    display: none;
  }
  
  &:hover {
    color: @brand-primary;
  }

  .owl-prev,
  .owl-next {
    display: inline-block;
    font: normal normal normal 14px/1 icon-font;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .owl-prev {
    left:0;
    &:before {
      content:"\e90c";
    }
  }
  
  .owl-next {
    right:0;
    &:after { content:"\e90d";  }
  }
}


.presentation-carousel {
  positin: relative;
  .owl-nav {
    @media (min-width: @screen-sm-min) { 
      top: auto;
      left: auto;
      right:0;
      bottom: 100%;
      width: auto;
      text-shadow: none;
      margin-bottom: @grid-gutter-width + 6px;
    }   
    
    .owl-prev {
      margin-right:8px; 
    } 
  }
}

#owl-producers {
  .owl-item {
    text-align:center;
  }
}
  

