@import "variables";

@font-face {
  font-family: '@{icomoon-font-family}';
  src:  url('@{icomoon-font-path}/@{icomoon-font-family}.eot?iidagt');
  src:  url('@{icomoon-font-path}/@{icomoon-font-family}.eot?iidagt#iefix') format('embedded-opentype'),
    url('@{icomoon-font-path}/@{icomoon-font-family}.ttf?iidagt') format('truetype'),
    url('@{icomoon-font-path}/@{icomoon-font-family}.woff?iidagt') format('woff'),
    url('@{icomoon-font-path}/@{icomoon-font-family}.svg?iidagt#@{icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '@{icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-list_alt {
  &:before {
    content: @icon-list_alt; 
  }
}
.icon-compare_arrows {
  &:before {
    content: @icon-compare_arrows; 
  }
}
.icon-conveyor {
  &:before {
    content: @icon-conveyor; 
  }
}
.icon-factory {
  &:before {
    content: @icon-factory; 
  }
}
.icon-production {
  &:before {
    content: @icon-production; 
  }
}
.icon-generate {
  &:before {
    content: @icon-generate; 
  }
}
.icon-conveyor-belt {
  &:before {
    content: @icon-conveyor-belt; 
  }
}
.icon-barcode {
  &:before {
    content: @icon-barcode; 
  }
}
.icon-price {
  &:before {
    content: @icon-price; 
  }
}
.icon-store {
  &:before {
    content: @icon-store; 
  }
}
.icon-shopping {
  &:before {
    content: @icon-shopping; 
  }
}
.icon-cart-plus {
  &:before {
    content: @icon-cart-plus; 
  }
}
.icon-shopping1 {
  &:before {
    content: @icon-shopping1; 
  }
}
.icon-at-sign {
  &:before {
    content: @icon-at-sign; 
  }
}
.icon-mail {
  &:before {
    content: @icon-mail; 
  }
}
.icon-email {
  &:before {
    content: @icon-email; 
  }
}
.icon-format_clear {
  &:before {
    content: @icon-format_clear; 
  }
}
.icon-high_quality {
  &:before {
    content: @icon-high_quality; 
  }
}
.icon-th-large {
  &:before {
    content: @icon-th-large; 
  }
}
.icon-automobile {
  &:before {
    content: @icon-automobile; 
  }
}
.icon-car {
  &:before {
    content: @icon-car; 
  }
}
.icon-asterisk {
  &:before {
    content: @icon-asterisk; 
  }
}
.icon-question-circle {
  &:before {
    content: @icon-question-circle; 
  }
}
.icon-history {
  &:before {
    content: @icon-history; 
  }
}
.icon-minus-square-o {
  &:before {
    content: @icon-minus-square-o; 
  }
}
.icon-plus-square-o {
  &:before {
    content: @icon-plus-square-o; 
  }
}
.icon-trash {
  &:before {
    content: @icon-trash; 
  }
}
.icon-plus {
  &:before {
    content: @icon-plus; 
  }
}
.icon-minus {
  &:before {
    content: @icon-minus; 
  }
}
.icon-th {
  &:before {
    content: @icon-th; 
  }
}
.icon-th-list {
  &:before {
    content: @icon-th-list; 
  }
}
.icon-chevron-left {
  &:before {
    content: @icon-chevron-left; 
  }
}
.icon-chevron-right {
  &:before {
    content: @icon-chevron-right; 
  }
}
.icon-eercast {
  &:before {
    content: @icon-eercast; 
  }
}
.icon-star {
  &:before {
    content: @icon-star; 
  }
}
.icon-star-o {
  &:before {
    content: @icon-star-o; 
  }
}
.icon-mobile {
  &:before {
    content: @icon-mobile; 
  }
}
.icon-address-book-o {
  &:before {
    content: @icon-address-book-o; 
  }
}
.icon-search {
  &:before {
    content: @icon-search; 
  }
}
.icon-envelope {
  &:before {
    content: @icon-envelope; 
  }
}
.icon-shopping-bag {
  &:before {
    content: @icon-shopping-bag; 
  }
}
.icon-angle-down {
  &:before {
    content: @icon-angle-down; 
  }
}
.icon-down2 {
  &:before {
    content: @icon-down2; 
  }
}
.icon-basket {
  &:before {
    content: @icon-basket; 
  }
}
.icon-visa {
  &:before {
    content: @icon-visa; 
  }
}
.icon-cancel-circle {
  &:before {
    content: @icon-cancel-circle; 
  }
}
.icon-close {
  &:before {
    content: @icon-close; 
  }
}
.icon-remove {
  &:before {
    content: @icon-remove; 
  }
}
.icon-delete {
  &:before {
    content: @icon-delete; 
  }
}
.icon-upload {
  &:before {
    content: @icon-upload; 
  }
}
.icon-load {
  &:before {
    content: @icon-load; 
  }
}
.icon-arrow {
  &:before {
    content: @icon-arrow; 
  }
}
.icon-floppy-disk {
  &:before {
    content: @icon-floppy-disk; 
  }
}
.icon-save {
  &:before {
    content: @icon-save; 
  }
}
.icon-switch {
  &:before {
    content: @icon-switch; 
  }
}
.icon-calendar {
  &:before {
    content: @icon-calendar; 
  }
}
.icon-date {
  &:before {
    content: @icon-date; 
  }
}
.icon-schedule {
  &:before {
    content: @icon-schedule; 
  }
}
.icon-time {
  &:before {
    content: @icon-time; 
  }
}
.icon-day {
  &:before {
    content: @icon-day; 
  }
}
.icon-link {
  &:before {
    content: @icon-link; 
  }
}
.icon-chain {
  &:before {
    content: @icon-chain; 
  }
}
.icon-url {
  &:before {
    content: @icon-url; 
  }
}
.icon-uri {
  &:before {
    content: @icon-uri; 
  }
}
.icon-anchor {
  &:before {
    content: @icon-anchor; 
  }
}
.icon-checkbox-checked {
  &:before {
    content: @icon-checkbox-checked; 
  }
}
.icon-checkbox {
  &:before {
    content: @icon-checkbox; 
  }
}
.icon-tick {
  &:before {
    content: @icon-tick; 
  }
}
.icon-checked {
  &:before {
    content: @icon-checked; 
  }
}
.icon-selected {
  &:before {
    content: @icon-selected; 
  }
}
.icon-edit {
  &:before {
    content: @icon-edit; 
  }
}
.icon-circle-arrow-down {
  &:before {
    content: @icon-circle-arrow-down; 
  }
}
.icon-circle-arrow-up {
  &:before {
    content: @icon-circle-arrow-up; 
  }
}
.icon-cogs {
  &:before {
    content: @icon-cogs; 
  }
}
.icon-building {
  &:before {
    content: @icon-building; 
  }
}
.icon-payment {
  &:before {
    content: @icon-payment; 
  }
}
.icon-sort-desc {
  &:before {
    content: @icon-sort-desc; 
  }
}
.icon-sort-asc {
  &:before {
    content: @icon-sort-asc; 
  }
}
.icon-shrink {
  &:before {
    content: @icon-shrink; 
  }
}
.icon-collapse {
  &:before {
    content: @icon-collapse; 
  }
}
.icon-minimize {
  &:before {
    content: @icon-minimize; 
  }
}
.icon-contract {
  &:before {
    content: @icon-contract; 
  }
}
.icon-truck {
  &:before {
    content: @icon-truck; 
  }
}
.icon-password {
  &:before {
    content: @icon-password; 
  }
}
.icon-tie {
  &:before {
    content: @icon-tie; 
  }
}
.icon-arrow-up {
  &:before {
    content: @icon-arrow-up; 
  }
}
.icon-cart1 {
  &:before {
    content: @icon-cart1; 
  }
}
.icon-checkmark {
  &:before {
    content: @icon-checkmark; 
  }
}
.icon-phone2 {
  &:before {
    content: @icon-phone2; 
  }
}
.icon-user {
  &:before {
    content: @icon-user; 
  }
}
.icon-wallet {
  &:before {
    content: @icon-wallet; 
  }
}
.icon-money {
  &:before {
    content: @icon-money; 
  }
}
.icon-cash {
  &:before {
    content: @icon-cash; 
  }
}
.icon-justice {
  &:before {
    content: @icon-justice; 
  }
}
.icon-balance {
  &:before {
    content: @icon-balance; 
  }
}
.icon-equality {
  &:before {
    content: @icon-equality; 
  }
}
.icon-court {
  &:before {
    content: @icon-court; 
  }
}
.icon-oldphone {
  &:before {
    content: @icon-oldphone; 
  }
}

