//
// Breadcrumbs
// --------------------------------------------------


.breadcrumb {
  padding: @breadcrumb-padding-vertical @breadcrumb-padding-horizontal;
  margin-bottom: @line-height-computed;
  list-style: none;
  background-color: @breadcrumb-bg;
  border-radius: @border-radius-base;

  > li {
    display: inline-block;
    @media (max-width: @screen-xs-max) {
    	margin-right: 1em;
    	margin-bottom: 1em;
    }
  }

  > .active {
    color: @breadcrumb-active-color;
  }
}
