/* buttons */
.btn {
  text-transform: uppercase;
  outline: none !important;
  
  .icon { 
    font-size: 18px;
    line-height: 1em;
  }
}


.btn-grid {
  padding: 5px;
  color: @brand-primary;
  margin-top: -3px;
  border-color: @brand-primary !important;
  
  &.active,
  &:hover,
  &:focus {
    color: #fff !important; 
    background-color: @brand-primary !important;
  }

}

.btn-default-primary {
  border-color: @brand-primary;
  color: @brand-primary !important;
  border-width: 2px;
  &:hover { background-color: @brand-primary-dark  }
}


/* gray button */
.btn-gray {
  background-color: @gray-lighter;
  color: @brand-primary;
  &:hover {
    background-color: @gray-light;
    color: #fff;
  }
}

/*btn-spinner*/
.btn-spinner .icon {
  margin:0;
}

/*btn-primary-dashed*/
.btn-primary-dashed {
  border: 1px dashed @brand-primary;
  color: @brand-primary;
  background-color: lighten(@gray-lighter,4%);
  
  &:hover {
    color: @brand-warning;
  }
}
