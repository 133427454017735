@icomoon-font-family: "icomoon";
@icomoon-font-path: "fonts";

@icon-list_alt: "\e929";
@icon-compare_arrows: "\e92a";
@icon-conveyor: "\e927";
@icon-factory: "\e927";
@icon-production: "\e927";
@icon-generate: "\e927";
@icon-conveyor-belt: "\e927";
@icon-barcode: "\e928";
@icon-price: "\e928";
@icon-store: "\e926";
@icon-shopping: "\e926";
@icon-cart-plus: "\e90b";
@icon-shopping1: "\e90b";
@icon-at-sign: "\e925";
@icon-mail: "\e925";
@icon-email: "\e925";
@icon-format_clear: "\e917";
@icon-high_quality: "\e913";
@icon-th-large: "\e924";
@icon-automobile: "\e91e";
@icon-car: "\e91e";
@icon-asterisk: "\e918";
@icon-question-circle: "\e90c";
@icon-history: "\e90d";
@icon-minus-square-o: "\f147";
@icon-plus-square-o: "\f196";
@icon-trash: "\f014";
@icon-plus: "\f067";
@icon-minus: "\f068";
@icon-th: "\f00a";
@icon-th-list: "\f00b";
@icon-chevron-left: "\f053";
@icon-chevron-right: "\f054";
@icon-eercast: "\f2da";
@icon-star: "\f005";
@icon-star-o: "\f006";
@icon-mobile: "\f10b";
@icon-address-book-o: "\f2ba";
@icon-search: "\f003";
@icon-envelope: "\f0e1";
@icon-shopping-bag: "\f290";
@icon-angle-down: "\e905";
@icon-down2: "\e906";
@icon-basket: "\e902";
@icon-visa: "\e901";
@icon-cancel-circle: "\e921";
@icon-close: "\e921";
@icon-remove: "\e921";
@icon-delete: "\e921";
@icon-upload: "\e922";
@icon-load: "\e922";
@icon-arrow: "\e922";
@icon-floppy-disk: "\e923";
@icon-save: "\e923";
@icon-switch: "\e920";
@icon-calendar: "\e91f";
@icon-date: "\e91f";
@icon-schedule: "\e91f";
@icon-time: "\e91f";
@icon-day: "\e91f";
@icon-link: "\e91d";
@icon-chain: "\e91d";
@icon-url: "\e91d";
@icon-uri: "\e91d";
@icon-anchor: "\e91d";
@icon-checkbox-checked: "\e91b";
@icon-checkbox: "\e91b";
@icon-tick: "\e91b";
@icon-checked: "\e91b";
@icon-selected: "\e91b";
@icon-edit: "\e919";
@icon-circle-arrow-down: "\e915";
@icon-circle-arrow-up: "\e916";
@icon-cogs: "\e910";
@icon-building: "\e911";
@icon-payment: "\e912";
@icon-sort-desc: "\e90e";
@icon-sort-asc: "\e90f";
@icon-shrink: "\e903";
@icon-collapse: "\e903";
@icon-minimize: "\e903";
@icon-contract: "\e903";
@icon-truck: "\e908";
@icon-password: "\e909";
@icon-tie: "\e90a";
@icon-arrow-up: "\e907";
@icon-cart1: "\e900";
@icon-checkmark: "\e904";
@icon-phone2: "\e942";
@icon-user: "\e971";
@icon-wallet: "\e91c";
@icon-money: "\e91c";
@icon-cash: "\e91c";
@icon-justice: "\e914";
@icon-balance: "\e914";
@icon-equality: "\e914";
@icon-court: "\e914";
@icon-oldphone: "\e91a";

