/* items */

/*category-link*/
[class*="col"]:last-child .category-link { 
  @media (max-width: @screen-xs-max) { 
    border-bottom: none; 
    padding-bottom:0;
  }
}

.category-link{
  margin-bottom:@grid-gutter-width/2;
  padding: 5px;
  border: 1px solid transparent;
  
  &:extend(.clearfix all);
  
  @media (min-width: @screen-xs-min) { 
    &:hover {
      border-color: @border-color;
    }
  }
  
  @media (max-width: @screen-xs-max) { 
    padding-bottom:@grid-gutter-width/2;
    border-bottom: 1px solid #ccc;
  }
  
}

.category-link-photo {
  width: 35%;
  float:left;
  padding-right: 10px;
  
  img { width: 100%; }
}

.category-link-content {
  width: 65%;
  float: left;
  height: 100px;
  overflow: hidden;
  
  .title { 
    font-size: @font-size-base; 
    margin-top: 0;
  }
  
  p { line-height: 1.1em; }
}

/*producers-item*/
.producers-item {
  padding: 10px 0;
  background: #ccc;
  line-height: 60px;
  text-align:center;
}

/*items-scroll*/
.items-scroll {
  @media (max-width: @screen-xs-max) { 
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    
    .content-scroll {
      width: 760px;
    }
  }
}


