/* news */
.new-item {
  border: 1px solid #ccc;
  padding: 10px;
  
  @media (max-width: @screen-sm-max) {
    margin-bottom: @grid-gutter-width/2;
  }
  
  time { color: @gray-light; }
}

.new-title { 
  font-size: 18px;
  font-weight: 600; 
  span { text-transform: uppercase; }
}

