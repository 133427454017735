/* sections */
.section { 
  padding-top: @grid-gutter-width/3; 
  padding-bottom: @grid-gutter-width/3; 
  padding-left: @grid-gutter-width; 
}

section + .filtr { 
  margin-top: @grid-gutter-width/3;
  @media (min-width: @screen-xs-min) { 
    margin-top: @grid-gutter-width;
  } 
}

.header, .section-header {
  padding: @grid-gutter-width/2;
  margin-bottom: @grid-gutter-width/2;
  text-align:left
}

.header{
  margin-bottom: 0;
  padding-bottom: 0;
}

aside, div.module{
    h3.header{
        margin:  0;
        padding: @grid-gutter-width/2 0 @grid-gutter-width/2 @grid-gutter-width/4;
        background-color: @well-bg;
        border: 1px solid @well-border;
        position: relative;
        border-radius: 0;
        font-size: @font-size-h4;
        font-weight: bold;
        .btn{
            position: absolute;
            right: @grid-gutter-width/5;
            top: @grid-gutter-width/5;
            @media(min-width: @screen-sm-min) {
                display: none;
            }
        }
        @media(max-width: @screen-xs-max) {
            border-radius: @border-radius-base;
            font-size: @font-size-base;
            text-align: left;
        }
    }
}

div.left > div.module:first-of-type h3.header{
	margin-top: 0px;
}

div.container > div.module{
	margin-bottom: @grid-gutter-width/2;
}

@media(min-width: @screen-sm-min) {
    .module div.collapse.mobile-collapsed{
        display: block!important;
    }
}

.incs {
	margin-bottom: @grid-gutter-width/4;
}

.section-title { 
  text-transform:uppercase;
  color:   @brand-primary;
  margin:  0px;
  padding: 0px;
  font-size: @font-size-h4;
  @media (min-width: 568px) {
    font-size: @font-size-h3;
  }
}

.header-light { background:@gray-lighter; }
.header-dark { 
  background: @brand-primary-dark;  
  .section-title { color: @gray-lighter; }
}

.section-footer {
  background-color: @gray-lighter;
  padding: 15px;
}

#system-errors{
  padding-top:@grid-gutter-width/3;
}