/* filtr */
#add-filt{
  @media (max-width: @screen-xs-max) {
    .collapse();
  }
}

.pagecontrol {
    padding: @grid-gutter-width/5;
    margin-bottom: @grid-gutter-width/2;
    label {
        font-size: @font-size-small;
    }
	@media (max-width: @screen-xs-min) {
		margin-top: @grid-gutter-width/2;
		* {
			font-size: @font-size-base!important;
		}
	}
}

.pagecontrol .collapse{
    div[class*="col-"]{
        margin-bottom: @grid-gutter-width/4;
    }
    @media (min-width: @screen-md-min) {
        height: auto;
        display: block;
        div[class*="col-"] {
            margin-bottom: 0px;
            margin-right: -2px;
            padding: 0px @grid-gutter-width/2;
            border-right: 1px inset;
        }
        .btn-sm, .btn-group-sm>.btn, .nav.pills-sm>li>a{
            padding: 3px 5px;
        }
    }
}

#pagecontrol-tgl{
    .pull-left {
        padding-top: 7px;
        font-weight: bold;
    }
    @media (min-width: @screen-md-min) {
        display:none;
    }
}

.add-filtr {
  border-top:1px solid #ccc;
  border-bottom:1px solid #ccc;
  margin-bottom: @grid-gutter-width;
  
  .row { margin:0; }
}

.filtr-list {
 padding:0;
  
  >li { 
    list-style: none;  
    padding:0;
    @media (max-width: @screen-xs-max) {
      margin-bottom: 10px; 
    }
     
    >a { 
      background: @gray-lighter;
      padding: 5px;
      text-decoration:none !important;
    }
  }
}


.filtr-size { 
  border-bottom:1px solid #ccc; 
  padding: @grid-gutter-width/2 0;
  margin:0;
  +li { margin-left: 10px; }
}

.check-list {
  padding: 0;
  list-style: none;
  
  &:extend(.clearfix all);
  
  >li { float: left;}
  
  label { font-weight: normal; }
}

.check-list-block {
  > li {  display: block; }
}

/*width-check*/
.check-2 {
  >li { width: 50%; }
}

.check-3 {
  >li { width: 33.3333%; }
}

.check-4 {
  >li { width: 25%; }
}

.filtr {
  .navbar-toggle  { 
    width: 100%; 
    float: none;
  }
}

.filtr-pagecontrol {
  padding: 10px 0;
}

/*filtr-item*/
.filtr-items {
  &:extend(.clearfix all);
  
  >li { 
    float: left; 
    padding-left: 2px;
    padding-right: 2px;
  }
}

.filtr-item {
  text-align: center;
  padding:2px;
  border: 1px solid #ccc;
  
  .filtr-icon {
    +.filtr-title { 
      border-top: 1px solid #ccc; 
      padding: 2px 0;
      margin-top: 2px;
    }
  }
  
  .filtr-title {
    font-weight: 600;
    color: @text-color;
  }
}

/*filtr-lamps*/
.filtr-lamps {
  >li  { 
    width: 20%; 
    margin-bottom: 10px;
  }
  
  @media (min-width: @screen-xs-min)  {
    >li  { 
      width: 10%; 
      margin-bottom: 0;
    }
  }
}


