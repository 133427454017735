/* global*/
html {
  height: 100%;
}

body { 
  height: 100%;
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  margin: @grid-gutter-width/2 0px;
}

.content {
  flex: 1 0 auto;
}

footer{
  z-index:1000;
  flex: 0 0 auto;
}

section {
  margin-bottom: @grid-gutter-width/2;
}

.row-no-padding {
  [class*="col-"] {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.input-group-addon { border-radius:0; }

div.modal-body > .module.row{
	margin-right: 0px;
	margin-left: 0px;
}

a.no-hover:hover{
	text-decoration: none;
}

input[type=checkbox] {
  line-height: normal;
  box-sizing: border-box;
  padding: 0;
  
  +label { margin-left: 5px; }
}

/*custom-control*/
.custom-control {
  opacity: 0;
  position: absolute;
  
  &:checked + label:before { 
    background-color: @brand-primary; 
    box-shadow: inset 0 0 0 3px #fff;
  }
    
  &,+label {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
  }
  
  +label {
    position: relative;
    padding-left: 24px;
    
    &:before{
      content: "";
      background: #fff;
      border: 2px solid #ccc;
      display: inline-block;
      vertical-align: middle;
      width: 18px;
      height: 18px;
      margin-right: 5px;
      position: absolute;
      left: 0;
    }
  }
}

/*position images*/
.right-img,
.left-img {    
  margin: @grid-gutter-width/2;
  
  @media (min-width: @screen-xs-min) { 
    max-width: 50%;
  }
}

.right-img { 
  float:right;    
  margin-right: 0;
}

.left-img { 
  float:left; 
  margin-left: 0;
}

/*row-sm*/
.row-sm {
  margin-left: -@grid-gutter-width/3;
  margin-right: -@grid-gutter-width/3;
  
  [class*="col"] {
    padding-left: @grid-gutter-width/3;
    padding-right: @grid-gutter-width/3;
  }
}

/*row-xs*/
.row-xs {
  margin-left: - @grid-gutter-width/4;
  margin-right: -@grid-gutter-width/4;
  
  [class*="col"] {
    padding-left: @grid-gutter-width/4;
    padding-right: @grid-gutter-width/4;
  }
}

.gly-spin {
  display: inline-block;
  -webkit-animation: spin 2s infinite linear;
  -moz-animation: spin 2s infinite linear;
  -o-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
}
@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(359deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
  }
}
@-o-keyframes spin {
  0% {
    -o-transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
#searchedResultBlock{
    max-height:550px; 
    overflow-y:auto;
}

.products.searchResultItem{
    display:flex; 
    align-items:center; 
    justify-content:space-between; 
    padding:5px; 
    padding-left:15px; 
    padding-right:15px; 
    border-bottom:solid 1px #dddddd;
}

.articleContainer{
    min-width:200px;
    font-size:13px;
}

.articleTitle{
    text-align:right; 
    font-size:10px; 
    padding-bottom:0px; 
    margin-bottom:-2px;
}

.articleValue{
    font-size:12px; 
    font-weight:bold; 
    text-align:right;
}

.categories.searchResultItem{
    padding:5px; 
    padding-left:15px; 
    padding-right:15px; 
    border-bottom:solid 1px #dddddd;
}

.manufacturers.searchResultItem{
        padding:5px; 
        padding-left:15px; 
        padding-right:15px; 
        border-bottom:solid 1px #dddddd;
}

.searchResultHeaderContainer{
    display:flex; 
    box-shadow: 0 6px 10px rgb(0,0,0,0.2); 
    position:absolute; 
    top:0px; 
    left:0px; 
    width:100%; 
    padding-left:15px; 
    padding-right:0px; 
    background-color:#eeeeee; 
    border-bottom:solid 1px #dddddd;  
    align-items:center; 
    justify-content:space-between;
}

#fullSearchButton{
    display:inline-block; 
    background-color:#1b1047; 
    cursor:pointer; 
    padding:7px 15px; 
    color:#ffffff;
}

#searchedResult{
    display: none; text-align:left;      
    box-shadow: 0 6px 12px rgb(0, 0, 0, 0.18);    
    overflow-y: hidden;
    padding-top: 35px;  
    position: absolute;  
    top: 49px;  
    border-left: solid 1px #dddddd;  
    width: calc(100% + 2px);  
    left: 0px; 
    background-color: #ffffff;   
    border-right: solid 1px #dddddd;  
    border-bottom: solid 1px #dddddd;
    z-index:1000;
}

#searchBackHider{
    width:100vw;
    height:100vh;
    position:fixed;
    top:0px;
    left:0px;
    background-color:rgba(0,0,0,0.3);
    display:none;
    z-index:999;
}

#searchedResultBlock::-webkit-scrollbar {
    width: 7px;
    height:7px;
}

#mod-finder-search-button{
    background-color:#ffffff !important;
}

/* Track */
#searchedResultBlock::-webkit-scrollbar-track {
    background: #eeeeee;
}


/* Handle */
#searchedResultBlock::-webkit-scrollbar-thumb {
    background: #1b1047;
}

/* Handle on hover */
#searchedResultBlock::-webkit-scrollbar-thumb:hover {
    background: #1b1047;
}

.buttonsSearchTotals{
    cursor:pointer;
}

.buttonsSearchTotals.active{
    background-color:#ffffff;
    border:solid 2px #cccccc;
    border-radius:5px;
    padding:0px 7px;
}

/* Breadcrumbs */
div.breadcrumbs{
	& > ul > li > span{
		padding: 10px 15px;
		display: block;
	}

	.icon-chevron-right{
		font-size:10px;
		position:absolute;
		padding: 0px;
		top:50%;
		left: -1em;
		margin-top:-0.5em;
	}
	
	& > ul > li{
		margin-left: 1em;
	}
	
	& > ul > li:first-of-type{
		margin-left: -6px;
		a {
			padding-left: 8px!important;
		}
	}
}