/* categories */
.category_intro_text{
    padding: @grid-gutter-width/4;
    padding-bottom: 0px;
    @media(max-width: @screen-xs-max) {
        overflow: hidden;
    }
    h1, h2, h3, h4{
    	margin: 0.3em 0px;
    	padding: 0px;
    }
    h2{
    	font-size: 22px
    }
    h3{
    	font-size: 19px
    }

}

h1.title-page{
	font-size: 2em;
	margin-top: @grid-gutter-width/3;
}

div.sutitle-page{
    padding: 6px;
    margin-bottom: 15px;
    border: 1px solid #1b1046;
    border-width: 0px 0px 0px 4px;
    font-style: italic;
    font-size: 110%;
	text-align: justify;
}

#pagecontrol{
	li{
		padding: 5px;
		&.active{
			color: #fff;
    		background-color: @brand-primary;
    		border-color: @brand-primary;
    		width: auto;
    		border-radius: 5px;
		}
	}
}

.vm-pagination{
    text-align:center;
}

.category-item {
    img{
    	margin: 4px;
    }
    
    h4{
        font-size: @font-size-h5;
        text-align: center;
        font-weight: bold;
        position: relative;
		top: 50%;
		transform: translateY(-50%);
		margin: 0px;
    }
    
    p{
        font-size: @font-size-small;
        word-wrap: break-word;
        text-align: justify;
        overflow: hidden;
        margin: 0px;
        max-height: 100%;
        display: none;
        @media (min-width: 568px) {
            display: block;
        }
    }
    
    a{
        display: block;
        &:hover,
        &:focus{
            text-decoration: none;
        }
    }
	
    .caption{
        padding: 4px;
        height: 58px;
    }

    .thumbnail {
        position: relative;
        overflow: hidden;
        height:   186px;
        padding:  0px;
    }
    
    @media(max-width: @screen-xs-max) {
        &:nth-child(odd){
            padding-right: @grid-gutter-width/4;
        }
        &:nth-child(even){
            padding-left: @grid-gutter-width/4;
        }
        .thumbnail{
            margin-bottom: @grid-gutter-width/2;
        }
    } 
    
    &.no-text{
    	.thumbnail {
    		height: 183px;
    	}
    }
}

.sidebar .category-item, .hide-text.category-item{
    .thumbnail{
        margin-bottom: @grid-gutter-width/2;
    }
    p {
        display:none;
    }
}

.sidebar .category-item{
    width: 50%;
    &:nth-child(odd){
        padding: 0 @grid-gutter-width/4 0 0;
    }
    &:nth-child(even){
        padding: 0 0 0 @grid-gutter-width/4;
    }
}