/* card product */
.card { 
  margin-bottom: @grid-gutter-width/2;
  @media (min-width: @screen-xs-min) { 
    margin-bottom: @grid-gutter-width;
  }
}

#float-p1{
    position: absolute;
    right:  @grid-gutter-width/2;
    margin-top: -@grid-gutter-width/3;
    @media (max-width: @screen-xs-max) { 
        display:none;
    }
}

.card-header{
    margin-top: @grid-gutter-width/3;
    .container-header{
        margin-bottom: @grid-gutter-width/3;
    }
    .title-page { 
        margin-top: 0; 
    }
}

#ruFlag{
 background: url(../img/ru-flag.png) no-repeat right;
 cursor: pointer;
 position: absolute;
 width:  133px;
 height: 54px;
 z-index:100;
 right:   0px;
 bottom: -17px;
 @media (max-width: @screen-xs-max) { 
   transform: scale(0.5);
   right: -28px;
 }
}

div.PricesalesPrice{
	font-size: 150%;
	font-weight: bold;
	margin-bottom: @grid-gutter-width/2;
}

/*main-card-info*/
.main-card-info {
  border-bottom: 1px dashed #ccc;
  margin-bottom: @grid-gutter-width/3;
  .card-art{
    color: @brand-warning;
  }
  .ruGost {
    background: url(../img/logo-rst.png) no-repeat right;
    padding-right: 43px;
    line-height: 30px;
    margin-top: -4px;
    cursor: pointer;
  }
  &:extend(.clearfix all);
  @media (max-width: @screen-xs-max) { 
    .ruGost{
        display:none;
    }
  }
  
  & > ul{
    padding: 0;
    margin-bottom: 0;
  }
}

/*discount-meters*/
.discount-meters { 
  background-color: @gray-lighter;
  text-align:center;
  display: table;
  width: 100%;
  border: 1px dashed @brand-primary-dark;
}

.tab-pane > h4{
    margin: @grid-gutter-width 0 @grid-gutter-width/4 0;
    font-size: 150%;
}

.series-desc{
    @media (max-width: @screen-xs-min) {
        font-size: 85%;
    }
    td{
        vertical-align: middle;
        padding: 6px;
    }
}

.series-table{
    border: 1px solid @border-color;
    width:  100%;
    @media (max-width: @screen-xs-min) {
        font-size: 85%;
    }
    caption{
        padding: @grid-gutter-width/2 0px @grid-gutter-width/4 0px;
        color:  @brand-primary-dark;
    }
    th{
        white-space: nowrap;
    }
    th,td{
        text-align: center;
        vertical-align: middle;
        padding: 3px;
        border: 1px solid @border-color;
        &.active{
            font-weight: bold;
        }
    }
}

.quick-add{
  @media (max-width: @screen-xs-min) { 
    margin-top: @grid-gutter-width/2;
  }
}

.add-quest{
  @media (min-width: @screen-xs-min) { 
    margin-bottom: @grid-gutter-width;
  }
}

.disc-meter {
  display: table-cell;
  width:   33.3333%;
  padding: @grid-gutter-width/4;
  border-right: 1px dashed @brand-primary-dark;
  &.active {
    background-color: @border-color;
    font-weight: 600;
  }
  &:last-of-type{
    border-right: none;
  }
}

.disc-sum {
  font-weight: 600;
  display:block;
}

.add-quest {
  @media (max-width: @screen-xs-max) { 
    .btn { margin-bottom: 10px; }
  }
}

.disc-quest {
  text-align: center;
  border-top: 1px dashed #ccc;
  border-bottom: 1px dashed #ccc;
  padding: 10px;
  margin-bottom: @grid-gutter-width;
}

.labeled-variant{
    padding-bottom: 15px;
	&:last-of-type{
		padding-bottom: 0px;
	}
    @media (max-width: @screen-xs-max) { 
        margin-bottom: 10px;
        &:last-of-type{
            margin-bottom: 0px;
        }
    }
    & > div{
        padding: @grid-gutter-width/4;
        border-radius: @border-radius-base;
        background-color: @gray-lighter;
        cursor: pointer;
        label{
            font-weight: normal;
            margin: 0px;
            cursor: pointer;
            text-align: center;
        }
        input{
            display: none;
        }
    }
    
    & > div.active,  & > div:hover, & > div:focus{
        background-color: @brand-primary-dark;
        color: white;
    }
}

/*delivery-info*/
div.delivery-info{
	li > a > i{
		display: block;
		font-size: smaller;
		font-weight: normal;
	}
	
	div.delivery-title, div.delivery-time, div.delivery-quest{
    	border: 1px dashed #ddd;
    	text-align: center;
	}
	
	div.delivery-time, div.delivery-quest{
    	border-top-width: 0px;
	}
	
	div.tab-content {
    	padding: 0;
    	border: solid 1px #ddd;
    	border-top-width: 0px;
	}
	
	div.delivery-time, div.delivery-quest{
		padding: 5px 15px;
	}
	
	div.delivery-time{
		font-weight: bold;
		span.label {
			padding-bottom: 2px;
    		padding-top: 2px;
    		font-size: 90%;
    		display: inline-block!important;
    		border: none!important;
		}
	}
	
	div.delivery-quest{
    	background-color: #eee;
	}
	
	div.delivery-description{
    	font-size: smaller;
    	padding: 5px 15px 0px 15px;
	}
	
}

div.main-image {
    padding-right: 83px;
    border: 1px dashed #ccc;
    position: relative;
    overflow: hidden;
    & > div.preimage{
		padding: @grid-gutter-width/2;
	}
}

div.main-image.no-add-images{
    margin-right: 0px;
    border-width: 1px;
}

div.main-image div.image {
    border: 0;
    background-color: #FFF;
    width: auto;
    height: auto;
    text-align: center;
    & a{
        @media (max-width: @screen-xs-min) { 
            line-height: 1em!important;
        }
        img{
    		height: auto;
    		max-width: 100%;
        }
    }
}

#vmdetimages {
    position: absolute;
    width:  83px;
    height: 100%;
    right:  0px;
    top:	0px;
    overflow: hidden;
    background-color: #eee;
    border-left: solid 1px #ddd;
}



div.no-add-images img{
    height: auto;
    max-width: 100%;
}

div.additional-images div.preimage div.image {
    width: 72px;
    height: 72px;
    margin: 0 auto 0 auto;
    border: 1px solid #ccc;
    background-color: White;
    overflow: hidden;
    & img{
        height: auto;
        max-width: 100%;
        vertical-align: middle;
        border: 0;
        line-height: 72px;
    }
}

div.imagebox{
	margin-bottom: @grid-gutter-width/2;
}

div.paramsbox{
	& div.deliveryParams{
		margin-top: 15px;
		clear: both;
	}
}

div.paybox{
    @media (max-width: 567px) {
		margin-top: 15px;
    }
	padding: 15px;
	border: 1px dashed #ccc;
}

/* description*/
.card-s-desc{
	margin-top: @grid-gutter-width/2;
	text-align: justify;
}
.card-desc{
	margin-bottom: @grid-gutter-width;
	h2{
		font-size: 25px;
	}
	h3{
		font-size: 20px;
	}
}

/*card-subinfo*/
.card-subinfo {
  background-color: @gray-lighter;
  padding: 10px 0;
  margin-bottom: @grid-gutter-width;
  border-top: 1px dashed #ccc;
  border-bottom: 1px dashed #ccc;
  
  ul {
    .list-inline();
    .list-unstyled();
    .nav-justified();
    
    text-align:center;
    margin-bottom:0;
    
    & >li {
      @media (max-width: @screen-xs-max) { 
        padding: 5px 0;
        display:block;
      }
    }
  }
}

.nav-properties {
   @media (max-width: @screen-xs-max) { 
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      .nav-tabs { 
        width: 400px; 
      }
  }
}


table.vmorder-done{ 
    td{
        vertical-align:middle!important;
    }
    img{
        display: none;
    }
    span.vmshipment_name, span.vmpayment_name{
        padding-right: 5px;
        font-weight: bold;
    }
}

.ico-pay {
	display: inline-block;
	width:  57px;
	height: 31px;
	border-radius: 2px;
	border: 1px solid #cacaca;
	background-position-y: -1px;
	background-image: url(../../../images/social/pay_icons.png);
}
.ico-pay.visa {
	background-position-x: 0;
}

.ico-pay.sberbank {
	background-position-x: 290px;
}
.ico-pay.mastercard {
	background-position-x: 233px;
}
.ico-pay.yandex {
	background-position-x: 174px;
}
