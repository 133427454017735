/* sidebar */
.sidebar{
  padding: 0 0 0 @grid-gutter-width/2;
  @media (min-width: @screen-xs-min) {
    float: left;
  }
  @media (min-width: @screen-sm-min) {
    width: 40%;
  }
  @media (min-width: @screen-md-min) {
    width: 30%;
  }
  @media (min-width: @screen-lg-min) {
    width: 25%;
  }
  @media(max-width: @screen-xs-max) {
    float: none;
    padding: 0;
    margin: 0 @grid-gutter-width/2;
  }
  & > div > .module.row, & > div > div.ajaxholder  > .module.row{
	margin-left: 0px;
    margin-right: 0px;
  }
}

#leftMobi > .module.row{
	margin-left: 0px;
    margin-right: 0px;
}

.tab-pane aside.sidebar{
    padding-left: 0;
}

.tab-pane main.main{
    padding-right: 0;
}

.filtr {
  .panel {
    box-shadow: none;
    position: relative;
    
    &:after{
      border-bottom: 1px solid #ccc;
      content:"";
      position:absolute;
      top: 100%;
      left: 0;
      right: 0;
    }
    
    .panel-heading { font-weight: 500; }
  }
  
  +section { margin-top: @grid-gutter-width/2;  }
}

  
#param-form {
  @media (max-width: @screen-xs-max) {
    .collapse();
  }
}
