/* ------header-------- */
.header-bar{
	background-color: @brand-primary-dark;
	button.navbar-toggle{
		color: @brand-primary;
		background-color: white;
		border-color: white;
    	position: absolute;
    	left: 10px;
	}
	button.navbar-toggle span{
		background-color:@brand-primary;
	}
}

#localization{
	margin: 0px;
	padding: 0px;
}

/* ----------------logo ---------*/

.logo-img > img{
	margin: 0px auto;
}

.logo-text{
	padding-top: 15px;
	& > img{
		margin: 0px auto;
	}
	& > div{
		text-align: center;
		font-size: 18px;
	}
}

.top-c1{
	margin-left: 40px;
}

.top-c2{
	margin-left: 15px;
}

.top-c1,.top-c2,.top-c3{
	white-space: nowrap;
	padding-top: 15px;
}

.top-info{
	& > i{
		display: block;
		float: left;
    	padding: 14px;
    	border-radius: 20px;
    	margin: 6px 10px 0px 0px;
    	box-shadow: 0 2px 6px rgba(0,0,0,0.2);
	}
	& > span{
		color: #a5a5a5;
 		font-size: 18px;	
	}
}

 .top-text{
 	font-size: 18px;
 	font-weight: bold;
 }

.logo-line-l{
	display: block;
    margin-top: 4px;
    width: 40%;
    height: 4px;
    background-color: #00e8ef;
    background: linear-gradient(90deg, #00e8ef, transparent);
}

.logo-line-r{
	display: block;
    width: 80%;
    margin-left: 20%;
    margin-top: 2px;
    height: 4px;
    background-color: #0083ff;
    background: linear-gradient(270deg, #0083ff, transparent);
}

#navigation{
	& > div{
		padding: 0px;
	}
	& > ul > li > a{
		color: white;
	}
	
	& > a:hover, & > a:focus{
		background-color: @brand-primary-light;
		text-decoration: none;
		color: white;
	}	
	
	li>a:hover, li>a:focus{
	    background-color: @brand-primary;
	    border-color: @brand-primary;
		color: white;
	}
	.nav .open>a, .nav .open>a:hover, .nav .open>a:focus {
	    background-color: @brand-primary-light;
	    border-color: @brand-primary-light;
	}
}

@media (max-width: @screen-sm-max) {
	.top-c1, .top-c2{
		margin-left: 0px;
	}
	.top-c3{
		margin-left: 15px;
	}
}

@media (max-width: @screen-xs-max) {
	.top-c1, .top-c2, .top-c3{
		text-align: center;
		padding-top: 0px;
	}
	.email-header{
		position: absolute;
		margin-top: 2em;
        margin-left: 0.5em;
	}
	header.header{
		padding:  @grid-gutter-width/2 0 0 0;
	}
    .navbar-nav .open .dropdown-menu {
        position: static;
        float: none;
        width: auto;
        margin-top: 0;
        background-color: transparent;
        border: 0;
        box-shadow: none
    }

    .navbar-nav .open .dropdown-menu>li>a,
    .navbar-nav .open .dropdown-menu .dropdown-header {
        padding: 5px 15px 5px 25px
    }

    .navbar-nav .open .dropdown-menu>li>a {
        line-height: 20px
    }

    #localization .navbar-nav .open .dropdown-menu>li>a {
        color: white;
    }

    .navbar-nav .open .dropdown-menu>li>a:hover,
    .navbar-nav .open .dropdown-menu>li>a:focus {
        background-image: none
    }
}

.min-order-message {
	line-height: 50px;
	color: white;
}