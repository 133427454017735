/* footer */
.footer, #top_link, #callbackbutton {
  background: @brand-primary-dark;
  color: #fff;
  padding: 20px 0;
  width: 100%;
}

.footer *{
  color: #fff!important;
}

.footer h4, .footer-pay{
  margin-top: 0px;
}

.agreement{
  padding-top: @grid-gutter-width/3;
}

.footer-pay{
  margin-bottom: @grid-gutter-width/3;
  height: @grid-gutter-width;
}

#top_link, #callbackbutton {
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: auto;
  padding: 10px 8px;
  border-radius: @btn-border-radius-large;
  opacity: 0.5;
  z-index:1001;
  -webkit-box-shadow: 0 6px 20px -5px rgba(0,0,0,.4),0 0 0 1px rgba(0,0,0,.06)!important;
  box-shadow: 0 6px 20px -5px rgba(0,0,0,.4),0 0 0 1px rgba(0,0,0,.06)!important;
}

#top_link:hover, #top_link:focus,
#callbackbutton:hover, #callbackbutton:focus{
  opacity: 1;
  text-decoration:none;
}