/* ------products------- */ 

/* с левой колонкой */
#productsHolder.products-table .item{
        max-width: 300px;
        width: 100%;
        margin: 0 auto;
        float: left;
        @media (max-width: @screen-sm-max) {
            width: 50%;
        }
        @media (max-width: 567px) {
            width: 100%;
        }
        @media (min-width: @screen-md-min){
            width: 33.33333%;
        }
        @media (min-width: @screen-lg-min){ 
            width: 25%;
        }
}    

#ddHistory{
	margin-top: -6px;
}

.badge-discount{
	color: red;
    background-color: yellow;
    font-size: 120%;
    padding: 5px 8px;
    height: 26px;
}

/* во всю страницу */
body > div.wrap > div.container > section,
div.vmsame{
    .products-table .item {
        max-width: 300px;
        width: 100%;
        margin: 0 auto @grid-gutter-width/2 auto;
        @media (min-width: 568px) {
            width: 50%;
            float: left;
        }
        @media (min-width: @screen-sm-min)  and (max-width: @screen-sm-max) { 
            width: 33.33333%;
            float: left;
        }
        @media (min-width: @screen-md-min){ 
            width: 20%;
            float: left;
        }
	}
}
/*product-item*/

.availability, .dotted{
    border-bottom: 1px dotted @brand-primary-dark;
    cursor: pointer;
}

.availability:hover, .dotted:hover,
.availability:focus, .dotted:focus{
    text-decoration: none;
}

.availability{
  & .in_stock {
      color:@brand-success;
      font-weight:bold;
  }
    
  & .out_stock {
      color:@brand-warning;
      font-weight:bold;
  }
    
  & .not_avaliable {
      color:@brand-danger;
      font-weight:bold;
  }
}

input.in_stock, td.in_stock{
	background: #7ce379;
	background: -moz-linear-gradient(left,  #fff 29%, #7ce379 100%);
	background: -webkit-gradient(linear, left top, right top, color-stop(29%,#fff), color-stop(100%,#7ce379));
	background: -webkit-linear-gradient(left,  #fff 29%,#7ce379 100%);
	background: -o-linear-gradient(left,  #fff 29%,#7ce379 100%);
	background: -ms-linear-gradient(left,  #fff 29%,#7ce379 100%);
	background: linear-gradient(to right,  #fff 29%,#7ce379 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fff', endColorstr='#7ce379',GradientType=1 );
}

input.out_stock, td.out_stock{
	background: #fdfd8a;
	background: -moz-linear-gradient(left,  #fff 29%, #fdfd8a 100%);
	background: -webkit-gradient(linear, left top, right top, color-stop(29%,#fff), color-stop(100%,#fdfd8a));
	background: -webkit-linear-gradient(left,  #fff 29%,#fdfd8a 100%);
	background: -o-linear-gradient(left,  #fff 29%,#fdfd8a 100%);
	background: -ms-linear-gradient(left,  #fff 29%,#fdfd8a 100%);
	background: linear-gradient(to right,  #fff 29%,#fdfd8a 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fff', endColorstr='#fdfd8a',GradientType=1 );
}

input.not_avaliable, td.not_avaliable{
	background: #e99696;
	background: -moz-linear-gradient(left,  #fff 29%, #e99696 100%);
	background: -webkit-gradient(linear, left top, right top, color-stop(29%,#fff), color-stop(100%,#e99696));
	background: -webkit-linear-gradient(left,  #fff 29%,#e99696 100%);
	background: -o-linear-gradient(left,  #fff 29%,#e99696 100%);
	background: -ms-linear-gradient(left,  #fff 29%,#e99696 100%);
	background: linear-gradient(to right,  #fff 29%,#e99696 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fff', endColorstr='#e99696',GradientType=1 );
}


#productDetailTabs {
    padding: 5px;
    border: 1px solid @border-color;
    border-radius: @btn-border-radius-base;

    li {
        margin: 0 10px -14px 10px;
        a {
            border-radius: @btn-border-radius-base;
            margin: 0;
            border: 0;
            font-weight: bold;
        }
        
    }
    
    li:first-child {
        margin-left: 0;
    }
    
    li.active {
        border-radius: @btn-border-radius-base;
        padding: 0 0 14px 0;
        background: url(../img/dark-pointer.png) no-repeat center bottom;
        a{
            background-color: #00182e;
            color: white;
        }
    }    
}

#product-chrs{
	margin-left: -5px;
	margin-right: -5px;
	
	& > div{
		padding-left: 5px;
		padding-right: 5px;	
		padding-top: 10px;
	}

    & .panel-heading{
        #gradient > .vertical-three-colors(#eee; #ccc; 25%; #e5e5e5);
    }
    
    & .panel-title a:hover, & .panel-title a:focus{
        text-decoration: none;
    }

    & .panel-body{
        padding: 0px;
        border-top-width: 0px;
        table {
            margin: 0px;
        }
    }
}

.old-price {
	text-decoration: line-through;
	color: @gray-light;
}

div.ribbon-wrapper {
    width: 120px;
    height: 120px;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: -21px;
}

div.ribbon-wrapper>div {
    font: bold 15px Sans-Serif;
    color: #333;
    text-align: center;
    text-shadow: rgba(255,255,255,0.5) 0 1px 0;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    position: relative;
    padding: 7px;
    width: 70px;
    background-color: #f88200;
    background-image: -webkit-gradient(linear,left top,left bottom,from(#f88200),to(#f8c388));
    background-image: -webkit-linear-gradient(top,#f88200,#f8c388);
    background-image: -moz-linear-gradient(top,#f88200,#f8c388);
    background-image: -ms-linear-gradient(top,#f88200,#f8c388);
    background-image: -o-linear-gradient(top,#f88200,#f8c388);
    -webkit-box-shadow: 0 0 3px rgba(0,0,0,0.3);
    -moz-box-shadow: 0 0 3px rgba(0,0,0,0.3);
    box-shadow: 0 0 3px rgba(0,0,0,0.3);
    cursor: pointer;
}

div.compare-holder{
	text-align: right;
	overflow: hidden;
	height:25px;
	& > a > span{
		font-size: 25px;
	}
	& > a:hover, & > a:focus{
		text-decoration: none;
		cursor: pointer;
	}
	a{
		display: inline-block;
		height: 25px;
		white-space: nowrap;
	}
	a.remove-from-compare{
		color: Red!important; 
	}
}

 .withDiscount{
	color: Red!important; 
 }

.product-item {
  position: relative;
  padding: @grid-gutter-width/4 @grid-gutter-width/4;
  border: 1px solid @border-color;
  border-radius: @btn-border-radius-base;
  margin-bottom: @grid-gutter-width/2;
  & > .row{
	height: 26px;
	max-height: 26px;
	overflow: hidden;
  }
  &:extend(.clearfix all);
  &:hover { 
      border-color: @border-color-dark; 
      box-shadow: 0 0 10px @border-color;
  }
  
  .btn-block {
    padding:  3px 5px;
    border-radius: @btn-border-radius-small!important;
  }
  
  .price-block{
    display: flex;
    align-items: center;
    height: 51px;
    font-size: 120%;
    padding-left: @grid-gutter-width/4;
  }
  
  .rest-sign{
    text-align: center;
    font-size: smaller;
  }
  
  .quantity-plus, .quantity-minus{
    border-radius: 5px;
    border: 1px solid #999;
    padding: 1px 4px 0px 4px;
  }
  
  .quantity-plus{
	margin-top: -2px;
	margin-bottom: 4px;
  }
  
  .addtocart-button{
    width: 100%;
    padding-left: 8px; 
  }
  
  .quantity-plus:hover, .quantity-minus:hover{
	background-color:#999;
  }

  form.product{
    padding-top: @grid-gutter-width/4;
  }
  
  .plus-minus{
	text-align: center;
	& > div{
		font-size: 10px;
    	margin: 0px auto;
	}
  }
  
  .row { 
    margin-left: -@grid-gutter-width/4;
    margin-right: -@grid-gutter-width/4;
  }
  
  .title { 
    height: 3.3em;
    overflow: hidden;
    font-weight: bold;
    font-size: 100%;
    line-height: 1.1em;
  }
  
  .skubrand {
    p {
        margin:0;
        white-space: nowrap;
    }
    @media (min-width: @screen-xs-min) {
    	/*не для телефонов*/
    	font-size: 85%;
    }
    overflow: hidden;
    max-height:  3.1em;
    line-height: 1.5em;
  }
  
  .beforeDiscountPrice{
    text-decoration: line-through;
  }
  
  .price-cell { 
    height: 37px;
    width: 70%;
    float: left;
	line-height: 20px;
    @media (min-width: @screen-xs-min) {
    	/*не для телефонов*/
    	font-size: 85%;
    	height: 34px;
    }
    overflow:    hidden;
    white-space: nowrap;
    span.main-price{
        font-size:   150%;
        font-weight: 600;
    }
  }
  
  .product-properties {
    color: @brand-primary-light;
    font-size: 90%;
    height: 3.75em;
    line-height: 125%;
    overflow: hidden;
  }
  
  .img-link{
	display: block;
	height: 130px;
  }
  
  .product-img {
	height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
    img { 
      max-height: 120px;
    }
  }
  
  .product-img.no-properties{
	margin: 1em 0;
  }

  .cart {
    width: 30%;
    float: left;
    text-align: center;
	button.btn-cicle.addtocart-button{
    	border-radius: 200%;
    	padding: 8px 5px;
    	margin: -0.5em 0 0 0;
    	.icon{
	    	font-size: 200%;
	    }
	}

  }
  
  .compare {
      [class^="icon-"], [class*=" icon-"] {
        color:@brand-warning;
      }
  }
  
}

/*row-header */
.row-header {
  color: #fff;
  background-image:linear-gradient(to bottom, #023f75, #01182d);
  
  thead>tr>th {
    border-bottom: none;
  }

  thead>tr>th,
  tbody>tr>th { 
    vertical-align: middle;  
    text-align:center;
    padding: 10px @grid-gutter-width/4;
    border: none;
  }
}

/*row-item*/
.row-item {
  [class*="col-"] {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    float:none;
    
    .title { text-align: left; }
    p { margin:0; }
    .price { display: block; }
  }
  .price-cell, 
  .cart {
    width: auto;
    float: none;
  }
}

/*product-status*/
.product-status {
  position: absolute;
  color: #fff;
  text-transform:uppercase;
  top:     5em;
  right:   6px;
  padding: 3px 8px;
  z-index:9;
  font-size: 80%;
  border-radius: @btn-border-radius-small;
  
  &.in_stock {
      background:@brand-success;
  }
    
  &.out_stock {
      background:@brand-warning;
    }
    
  &.not_avaliable {
      background:@brand-danger;
    }
}

/*---catalog-item---*/
.catalog-item { 
  text-align:center;
  a {
    display: block;
    padding: 10px !important;
    border: 1px solid transparent;
    white-space: normal !important;
    color: @brand-primary !important;
    font-weight: 600 !important;
    line-height: 125%;
    text-decoration: none;
    overflow: hidden;
    max-height: 230px;
    &:hover { 
          border-color: @border-color-dark; 
          box-shadow: 0 0 10px @border-color;
          background-color: transparent!important;
    }
    & > div {
		padding: 1px;
		max-width: 145px;
		max-height: 145px;
    	margin: 0px auto;
		#gradient > .vertical(#ccc, #fff);
		& > div {
			#gradient > .vertical(#f0f0f0, #fff);
			& > img {
				-webkit-filter: drop-shadow(2px 2px 3px #999);
				filter: drop-shadow(2px 2px 3px #999);
			}
		}
    }
  }
  
  p { height: 2.5em; margin-top: 0.5em; }
  img { 
    margin: 0 auto; 
    + p { margin-top: 10px; }
  }
}

.products-details{
	div.title{
		padding: 0px;
		margin: 0px 150px 10px 0px;
		height: auto;
		font-size: 120%;
	    @media (max-width: 567px) {
	        margin: 2em 0 1em 0;
	    }
	}

	div.skubrand{
		margin-bottom: 10px;
		clear: both;
		& > p{
			float: left;
			padding: 0 3em 0 0;
			font-size: 120%;
		}
	}
	
	div.product-status{
		top:   8px;
		right: 8px;
	}
	
	div.product-img{
		padding: 10px;
		margin:  0 32px 1em 0;
		min-width: 120px;
		min-height: 120px;
		height: auto;
		float: left;
		border: 1px solid #ccc;
	    @media (max-width: 567px) {
	        margin: 0px 1em 1em 1em;
	        float: none;
	        clear: both;
	    }
	}
	
	div.product-properties{
		margin: 0px;
		height: auto;
	    @media (max-width: 567px) {
	        margin: 0px 1em;
	    }
		ul{
			margin:  0px;
			padding: 0px;
			list-style: none;
			li{
				padding-bottom: 0.75em; 
			}
		}
	}
	
	div.price-cell{
		height: auto;
		position: absolute;
    	right: 0px;
    	bottom: 0px;
    	max-width: 150px;
    	span.main-price{
    		display: block;
    		font-size: 200%;
    	}
		@media (max-width: 567px) {
	    	position: relative;
	    	max-width: unset;
	    	clear: both;
	    	margin: 1em 0 2em 0;
	    	line-height: normal;
		}
	}
	
	div.price-cell, div.cart div.input-group{
		margin-bottom: 10px;
	}
	
	div.cart{
		clear: both;
		& input.quantity-input, & button.addtocart-button{
			height: 30px;
		}
	    @media (max-width: 567px) {
	        width: 100%;
	        float: none;
	        clear: both;
	        margin-bottom: 1em;
	    }
	}	
}

div.card-body{
	button.addtocart-button{
		width: 100%;
	}
}

.container-param{
	width: 100%;
	overflow:hidden;
	font-size: 90%;
}

.row-param{
	display: flex;
	.paramA{
		white-space: nowrap;
	}
	.paramB{
		flex-basis:100%;
		padding: 0px 3px 0px 6px;
	}
	.paramC{
		white-space: nowrap;
		font-weight: bold;
	}
	.tab-param{
		font-size: 75%;
		border-bottom: 1px dashed rgb(220, 220, 220);
	}
}

.old_p{
	text-decoration: line-through;
	font-size: smaller;
	color: #9f9f9f;
}

/*products-row*/
.products-row {
  text-align: center;
  overflow-wrap: break-word;
  .items-scroll();
  .product-status,
  .product-properties,
  .compare-holder,
  .skubrand,
  .table-text { display: none; }
  
  .product-status{
    position: absolute;
    display: block;
    top: auto;
    right:  0px;
    bottom: 0px;
	padding: 1px 4px;
    @media (min-width: @screen-sm-min) {
    	width: 90px;
    	padding: 1px;
    }
    height: 16px;
    border-radius: @border-radius-small 0 0 0;
  }
  
  .row-header {
    margin-left: -@grid-gutter-width/4;
    margin-right: -@grid-gutter-width/4;
  }
  
  .item{
    margin-top: -1px;
  }
  
  :first-child{
	margin-top: 0px;
  }
  
  .product-item {
    border-radius: 0;
    margin-bottom: 0;
    padding: @grid-gutter-width/4 3px;
    height: 57px;
    max-height: 57px;
  }
 
  .quantity-box .quantity-controls,
  .quantity-box .quantity-input{
    display:none;
  }
  
  .quantity-box .input-group-addon,
  .quantity-box .input-group-btn {
    width: auto;
  }
  
  .quantity-box button, .quantity-box input{
    padding: 2px 5px;
    height: 26px;
  }

  .quantity-box button{
	margin: 0px;
	padding: 4px 8px 4px 8px;
	@media (max-width: @screen-sm-max) {
		border-radius: 4px!important;
	}
  }

  @media (min-width: @screen-lg-min) {
    .quantity-box .input-group-addon,
    .quantity-box .input-group-btn {
		width: 1%;
    }
    .quantity-box .quantity-controls{
		display:table-cell;
    }
  }
  
  @media (min-width: @screen-md-min) { 
    .quantity-box .quantity-input{
        display:table-cell;
    }
    .quantity-box .btn-block{
      .border-left-radius(0)!important;
    }
    .title,
    .price-cell,
    .cart,
    .compare-holder{
      top: 0px;
      display: block; 
    }
  }

  .product-img {
    .make-xs-column(1.5; @gutter: @grid-gutter-width/2);
    .make-sm-column(1.1; @gutter: @grid-gutter-width/2);
    .make-md-column(1; @gutter: @grid-gutter-width/2);
    .make-xs-column-pull(6.1);
    .make-sm-column-pull(6.3);
    .make-md-column-pull(7);
    height: auto;
    padding: 0px;
    img {
        max-height: 43px;
    }
  }
 
  .title {
    text-align: left;
    .make-xs-column(6;@gutter: @grid-gutter-width/2);
    .make-sm-column(6.2;@gutter: @grid-gutter-width/2);
    .make-md-column(7;@gutter: @grid-gutter-width/2);
    .make-xs-column-push(1.5);
    .make-sm-column-push(1.2);
    .make-md-column-push(1);
    padding: 0px;
    margin: 0px;
    font-size: @font-size-small;
    position: relative;
    > a{
    	vertical-align: middle;
    	display: table-cell;
    	height: 3.3em;
    }
  }
 
  .price-cell {
     height: auto;
	 margin: 0px;
     word-wrap: normal;
    .make-xs-column(2.5;@gutter: @grid-gutter-width/2);
    .make-sm-column(2;@gutter: @grid-gutter-width/2);
    .make-md-column(1;@gutter: @grid-gutter-width/2);
    .make-xs-column-push(0);
    .make-md-column-push(1);
    span.salesPrice > span:before{
    	content: ' '; display: block;
    }
    .row-text {
      display: block;
      font-size: @font-size-small;
    }
    & *{
    	font-size: @font-size-base;
    	font-weight: normal;
    	line-height: 0.8em;
    }
  }
  
  form.product{
    padding: 0px;
  }
  
  button.quantity-plus{
	border-radius: 0;
  }
  
  .cart { 
    .make-xs-column(2;@gutter: @grid-gutter-width/2);
    .make-sm-column(1.5;@gutter: @grid-gutter-width/2);
    .make-md-column(2;@gutter: @grid-gutter-width/2);
    .make-xs-column-push(0);
    .make-sm-column-push(1.2); 
    .make-md-column-push(1); 
  } 
  
  .compare-holder {
	text-align: center;
    line-height: 40px;
    .make-md-column(1;@gutter: @grid-gutter-width/2);
    .make-md-column-pull(3);
    .make-lg-column-pull(3);
  } 
}

.products-row.havesku{
  @media (min-width: @screen-md-min) { 
    .skubrand{
      top: 0px;
      display: block; 
    }
  }
  .title {
	.make-sm-column(5;@gutter: @grid-gutter-width/2);
    .make-md-column(4;@gutter: @grid-gutter-width/2);
    .make-md-column-push(3.5);
  }
  .skubrand {
    .make-md-column(2.5;@gutter: @grid-gutter-width/2);
    .make-md-column-pull(8.5);
  }
  .product-img {
	.make-md-column(1; @gutter: @grid-gutter-width/2);
    .make-sm-column-pull(5);
    .make-md-column-pull(4);
  }
  .price-cell {
    .make-md-column(1;@gutter: @grid-gutter-width/2);
    .make-md-column-push(4);
  } 
  .cart { 
    .make-md-column(2;@gutter: @grid-gutter-width/2);   
    .make-sm-column-push(2);
    .make-md-column-push(4); 
  }
  .compare-holder {
  	.make-md-column(1.5;@gutter: @grid-gutter-width/2);
    .make-md-column-pull(0.5);
  }
}

/*products-table*/
.products-table {
  .make-row(@gutter: @grid-gutter-width/2);
  
  .item {
    .make-sm-column(4; @gutter: @grid-gutter-width/2);
    .make-md-column(3; @gutter: @grid-gutter-width/2);
  }
    
  .compare { 
	clear: both;
	text-align: right;
    margin: 8px 0px 2px 0px;
    font-size: 90%;
  }

  .skubrand{
    clear: both;
  }
  
  .row-text,
  .row-header { display: none; }
  
  .price-cell > div{
		position: relative;
		top: 50%;
		transform: translateY(-50%);
		& > a{
			display: block;
		}
    } 
}

/*panel-group-product*/
.panel-group-product {  
  .panel-heading { padding: 10px; }
  .panel-body { padding: 0; }
}

.panel-heading {
  &:extend(.clearfix all);
}

/*accordion-counter*/
.accordion-counter {
  float: right;
  background-color: @gray-light;
  color: #fff;
  text-align: center;
  padding: 2px 8px;
}