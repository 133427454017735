div.flat-button {
	padding: 0px @grid-gutter-width/3;
	& > a{
		max-height: 48px;
		background-color: @brand-primary-dark;
		color: #fff;
		display: block;
		text-align: center;
		padding: 14px 0px;
		width: 100%;
	}
	& > a:hover, & > a:focus{
		background-color: @brand-primary-light;
		text-decoration: none;
		color: white;
	}	
	li>a:hover, li>a:focus{
	    background-color: @brand-primary-dark;
	    border-color: @brand-primary-dark;
		color: white;
	}
	.nav .open>a, .nav .open>a:hover, .nav .open>a:focus {
	    background-color: @brand-primary-dark;
	    border-color: @brand-primary-dark;
	}
}

#mod-finder-searchform{
	padding: 0px @grid-gutter-width/3;
}

#mod-finder-searchform > div.input-group{
    border: 1px solid #ccc;
	position:relative;
	z-index:1000;
}

#mod-finder-search-button{
	width: 46px;
	height: 46px;
	background-color: white;
}

#mod-finder-searchword-suggest{
    box-shadow: none;
    outline: none !important;
    border: none;
    width: 100%;
    line-height: 44px;
    padding: 0px @grid-gutter-width/3;
}

#menu-toggler{
	min-height: 46px;
	min-width: 46px;
	position: relative;
	text-transform: uppercase;
	& > div{
		display: inline-block;
	}
	& > div.tgl-holder{
		display: block;
		position: absolute;
		left: 8px;
		width: 30px;
		height: 46px;
		top: 0px;
	}
}