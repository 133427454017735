/* typography*/
.title-page {
  color: @brand-primary;
}

.title { 
  font-size: 16px;
}

.title-chapter {
  font-size: 26px;
  margin-bottom: 10px;
}

.title-part {
  font-size: 18px;
}

.text-bold { 
  font-weight: 600;
}

.pre-line {
  white-space: pre-line;
}

.popover-content > ul{
    margin: 0;
    font-size: 90%;
    line-height: 125%;
    padding-left: 10px;
    > li{
        margin: 0;
        padding: 3px 0;
    }
}