/* pages */
@media print {
	.container{
		width: 100%!important;
	}
	.full-print{
		width: 100%!important;
	}

}

.main {
  @media (min-width: @screen-xs-min) { 
     float:left;
  }
  @media (min-width: @screen-sm-min) {
    width: 60%;
  }
  @media (min-width: @screen-md-min) {
   width: 70%;
  }
  @media (min-width: @screen-lg-min) {
    width: 75%;
  }
 
  padding: 0 @grid-gutter-width/2;
  
  .add-filtr { border-top: none; }
}

.col-md-20{
  @media (min-width: @screen-md-min) {
    width: 20%;
  }
}

/*container-header*/
.container-header {
  border-bottom: 1px dashed #ccc;
  margin-bottom: @grid-gutter-width/2;
  &:extend(.clearfix all);
  
  .title-page  { 
    @media (min-width: @screen-xs-min) { 
      float:left; 
      +.btn { float: right; }
    }
  }
}

