/* tabs */
.nav-tabs>li>a {
  font-weight: 600;
}

.tab-content {
  padding: @grid-gutter-width/2 0;
}

.tab-pane .panel-body{
  tbody {
    tr:nth-child(odd) { background-color: @gray-lighter; }
    td { width: 50%; }
  }
}